import { readableUrl } from "@wix/communities-blog-client-common";

export const getPathWithoutSectionUrl = ({
  route,
  topology,
  shouldNavigateToFeed,
}) => {
  const { sectionUrl, postPageSectionUrl, baseUrl } = topology;
  const readableBaseUrl = readableUrl(baseUrl);
  const currentSectionUrl = shouldNavigateToFeed
    ? readableBaseUrl === route
      ? readableBaseUrl
      : readableUrl(sectionUrl)
    : readableUrl(postPageSectionUrl);
  return route.startsWith(currentSectionUrl)
    ? route.slice(currentSectionUrl.length)
    : route;
};
